// extracted by mini-css-extract-plugin
export var body = "_404-module--body--3e95b";
export var bodyLarge = "_404-module--bodyLarge--395f2";
export var bodyLargeLight = "_404-module--bodyLargeLight--b3786";
export var bodySmall = "_404-module--bodySmall--c32f6";
export var bold = "_404-module--bold--d0369";
export var button = "_404-module--button--890df";
export var eyebrow = "_404-module--eyebrow--7c06e";
export var filter = "_404-module--filter--ec035";
export var head = "_404-module--head--ab682";
export var homepageHeadline = "_404-module--homepageHeadline--76993";
export var internalHeadline = "_404-module--internalHeadline--a6b02";
export var italic = "_404-module--italic--bdf39";
export var link = "_404-module--link--39551";
export var linkSmall = "_404-module--linkSmall--1c0ad";
export var message = "_404-module--message--e5c0a";
export var quote = "_404-module--quote--f608b";
export var subMessage = "_404-module--subMessage--51d29";
export var wrap = "_404-module--wrap--ace06";