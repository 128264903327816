import * as React from "react";



import Button from "../components/button";
import Frame from "../components/frame";
import { HeadMeta } from "../components/head-meta";



import * as styles from "../styles/404.module.scss";


export const Head = () => {
	return <HeadMeta title="404 Page Not Found" />;
};
const NotFoundPage = () => {
	return (
		<Frame contained="true">
			<div className={styles.wrap}>
				<div>
					<h1 className={styles.head}>404</h1>
					<div className={styles.message}>Oops! That page can’t be found.</div>
					<div className={styles.subMessage}>
						It looks like nothing was found at this location.
					</div>
					<Button className={styles.button} to="/">
						Homepage
					</Button>
				</div>
			</div>
		</Frame>
	);
};

export default NotFoundPage;
